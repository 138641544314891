<template>
  <div class="summary">
    <p>
      If you're reading this, you either found one of my cards or I've
      personally told you about
      <a a href="#" @click="trackArticle('from my story link')">
        my story
      </a>
      that was published in the <a href="#" @click="trackArticle('from image')">
      <img src="./../assets/images/logo_epochtimes.svg" alt="Epoch Times"></a>
    </p>
    <p>
      I started my pleas to end the mandate with my HR dept, then a letter directly to the CEO, then let the media know. I hope this story can be an encouragement for you to stand in your own battle.  
    </p>
    <p>
      <img src="./../assets/images/flag.png" alt="flag" />
    </p>
    <p>
      <span style="font-style: italic; font-weight: bold">Nov. 30th, 2021</span> was my last official day at Krispy Kreme. After a lengthy fight, all of my pleas were either ignored or outright denied and I was fired because of non-compliance to the vaccination policy.      
    </p>
    <p>
      While I am on to a new journey, we simply MUST stop these ridiculous vaccine mandates from any employer, at any time, for any reason. The answer is <strong>us</strong>. This fight does not stop until the mandate craziness does.
    </p>
  </div>
<div>
<img src="./../assets/images/divider-png-512.png" alt="divide" />
</div>

  <div class="details">
    <ol>
      <li class="goodtimes"><strong>Praying</strong> </li>
      <li>If you pray, please pray for those affected by these mandates. Good people are being fired in every industry.</li>
      <li class="goodtimes"><strong>Reading</strong></li>
      <li> 
        Keep up to date on ongoing mandate action on my free <a href="#" @click="trackBlog('details')">blog</a>.
      </li>
      <li class="goodtimes"><strong>Speaking Up</strong></li> 
      <li>Download this <a href="#" @click="trackFlyer">flyer</a> and take it to your local Krispy Kreme. Let them know that corporate did this. They probably don't know.</li>
      <li class="goodtimes"><strong>Emailing Krispy Kreme</strong></li>
      <li>Krispy Kreme investor relations: <a href="#" @click="trackInvestorRelations">KrispyKremeIR@edelman.com</a>. Tell them you are going to take your business elsewhere unless they reverse their policy.</li>
      <li class="goodtimes"><strong>Telling Me</strong></li>
      <li>Let me know what you've done or want to do with this info. Send me your own vax mandate story and I'd love to pray and encourage you via <a href="#" @click="trackEmail('mailto:tellmehow@ididnotcomply.com')" title="Email me" >
            email.</a>
      </li>
    </ol>
  </div>
<div>
<img src="./../assets/images/divider-png-512.png" alt="divide" />
</div>

  <div class="index">
    <ul>
      <li>
        <p>
          <a href="#" @click="trackBlog('icon')" title="My Substack Blog">
            <font-awesome-icon icon="blog" size="3x" />
          </a>
        </p>
      </li>
      <li>
        <p>
          <a href="#" @click="trackEmail('mailto:tellmehow@ididnotcomply.com')" title="Contact Me">
            <font-awesome-icon icon="envelope" size="3x" />
          </a>
        </p>
      </li>
      <li>
        <p>
          <a href="#" @click="trackEmail('mailto:tellmehow@ididnotcomply.com?subject=John, tell my story!')" title="Tell your story" >
            <font-awesome-icon icon="user-friends" size="3x" />
          </a>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  inject: ["mixpanel"],
  name: "index",
  props: {
    msg: String,
  },
  created() {
    this.mixpanel.track("Access site Index");
  },
  methods: {
    trackFlyer(){
      this.mixpanel.track('flyer downloaded', {distinct_id: 'vaxMandateDownload'});
      window.open('https://www.dropbox.com/s/u51lmext5ol4v70/KK-Vaccine-Mandate-Flyer.jpg?dl=0');
    },
    trackArticle(origin){
      this.mixpanel.track('Go to Epoch Times', {distinct_id: 'epochTimes '+ origin});
      window.open('https://www.theepochtimes.com/krispy-kreme-begins-vaccine-mandates-employee-prepares-to-be-terminated_4070211.html');
    },
    trackInvestorRelations(){
      this.mixpanel.track('email investor relations', {distinct_id: 'email created'});
      window.open('mailto:KrispyKremeIR@edelman.com?subject=Stop your vaccine mandate!');
    },
    trackBlog(origin){
      this.mixpanel.track('blog link clicked', {distinct_id: 'blog link clicked: ' + origin})
      window.open("https://14thelamb.substack.com/p/there-was-no-response")
    },
    trackEmail(mailToLink){
      this.mixpanel.track('email to site', {distinct_id: 'email create to go to site: ' + mailToLink});
      window.open(mailToLink);
    }
  }
  
};

</script>

<style scoped>
ol {
  padding-left: 0px;
  list-style-type: none;
}
ol li {
  padding: 5px 0 5px 0px;
  position: relative;
  display: block;
}
.goodtimes {
  font-family: "Goodtimes"; 
}
ul {
  padding-left: 0px;
  list-style-type: none;
}
ul li {
  display: inline-block;
  margin: 0 12px;
}
a {
  color: #42b983;
}
</style>