import { createApp } from "vue";
import App from "./App.vue";
import VueMixpanel from 'vue-mixpanel'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserCircle, faUserFriends, faEnvelope, faHandRock, faBlog } from "@fortawesome/free-solid-svg-icons";
import { faAngry } from "@fortawesome/free-regular-svg-icons";

library.add(faAngry);
library.add(faBlog);
library.add(faUserCircle);
library.add(faUserFriends);
library.add(faEnvelope);
library.add(faHandRock);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const myApp = createApp(App);
myApp.use(VueMixpanel, {
    token: "8ed6e177613c62205f9b994099b7e1ae",
    config: {
      debug: false
    }
  });
myApp.component("font-awesome-icon", FontAwesomeIcon);
myApp.mount("#app");
