<template>
  <img alt="Vue logo" src="./assets/images/title.png" />
  <Index msg="Don't comply" />
  <title>I Did NOT comply</title>
</template>
<script>

import Index from "./components/Index.vue";

export default {
  name: "App",
  components: {
    Index
  },
};

<link href="/assets/fonts/fontawesome/css/all.css" rel="stylesheet" />
</script>

<style>
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./assets/fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Goodtimes";
  src: local("goodtimes"),
    url(./assets/fonts/goodtimes/goodtimes/GOODTIME.ttf) format("truetype");
}
#app {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.summary {
  font-size: 110%;
  text-align: center;
  margin-right: 10%;
  margin-left: 10%;
  object-fit: cover;
}

.details {
  font-size: 110%;
  text-align: center;
  margin-right: 10%;
  margin-left: 10%;
}
</style>
